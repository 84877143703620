import { Controller } from "@hotwired/stimulus"
import { useHover } from 'stimulus-use'

let timeoutId;
const debounce = (func, delay) => {
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export default class extends Controller {
    static targets = ["search", "item", "menu", "hamburger", "locale", "login"];

    connect() {
        useHover(this, { element: this.element });
    }

    search_toggle() {
        this.menuTarget.classList.add('hidden');
        this.hamburgerTarget.classList.add('hidden');
        this.loginTarget.classList.add('hidden');
        if (this.hasLocaleTarget) {
        this.localeTarget.classList.add('hidden');
        }
        this.searchTarget.classList.toggle('hidden');
        document.getElementById('find-edit').focus();
    }

    locale_toggle() {
        this.menuTarget.classList.add('hidden');
        this.searchTarget.classList.add('hidden');
        this.hamburgerTarget.classList.add('hidden');
        this.loginTarget.classList.add('hidden');
        this.localeTarget.classList.toggle('hidden');
    }

    login_toggle() {
        this.menuTarget.classList.add('hidden');
        this.searchTarget.classList.add('hidden');
        this.hamburgerTarget.classList.add('hidden');
        if (this.hasLocaleTarget) {
            this.localeTarget.classList.add('hidden');
        }
        this.loginTarget.classList.toggle('hidden');
    }

    hamburger_toggle() {
        this.searchTarget.classList.add('hidden');
        this.loginTarget.classList.add('hidden');
        if (this.hasLocaleTarget) {
            this.localeTarget.classList.add('hidden');
        }
        this.hamburgerTarget.classList.toggle('hidden');
        if (!this.menuTarget.classList.contains("hidden")) {
            this.menuTarget.classList.add('hidden');
        }
    }

    menu_toggle(event) {
        event.preventDefault();
        this.searchTarget.classList.add('hidden');
        this.loginTarget.classList.add('hidden');
        if (this.hasLocaleTarget) {
            this.localeTarget.classList.add('hidden');
        }
        this.menuTarget.classList.toggle('hidden');
        document.getElementById('product-item').classList.toggle('underline_menu_item');

    }

    hide() {
        this.itemTargets.forEach((s) => {
            s.classList.add('hidden');
        });
        document.getElementById('product-item').classList.remove('underline_menu_item');

    }

    mouseLeaveDebounced = debounce(() => {
        this.hide();
    }, 1000);

    mouseLeave() {
        this.mouseLeaveDebounced();
    }

    mouseEnter() {
        clearTimeout(timeoutId);
    }
}
